@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '../node_modules/codemirror/lib/codemirror';
@import '../node_modules/codemirror/theme/material';
@import '../node_modules/codemirror/theme/moxer';
@import '../node_modules/codemirror/theme/oceanic-next.css';
@import '../node_modules/codemirror/theme/abcdef.css';
@import '../node_modules/codemirror/theme/dracula.css';


html, body { height: 100%; }
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #E4F0FF;
}

.container {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 0px;
    grid-template-areas:
    "nav header"
    "nav content"
    "footer footer";
    height: 100%;
  }

  .adapter-details-container {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 0px;
    grid-template-areas:
    "adapter-details-header adapter-details-header"
    "adapter-details-nav adapter-details-content";
    height: 100%;
  }

  .adapter-integration-container {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 0px;
    grid-template-areas:
    "adapter-integration-header adapter-integration-header"
    "adapter-integration-nav adapter-integration-content";
    height: 100%;
  }

  .security-container {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 0px;
    grid-template-areas:
      "security-nav security-content"
      "security-nav security-content";
    height: 100%;
  }

  .config-container {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 0px;
    grid-template-areas:
        "config-nav config-content"
        "config-nav config-content";
    height: 100%;
  }

 app-header {
    grid-area: header;
    padding: 0px 10px;
    background-color: black;
  }

  app-adapter-details-header {
    color: black;
    font-size: 14px;
    grid-area: adapter-details-header;
    text-align: left;
    padding: 0;
    background-color: #ffffff;
  }

  app-adapter-integration-header {
    color: black;
    font-size: 14px;
    grid-area: adapter-integration-header;
    text-align: left;
    padding: 0;
    background-color: #ffffff;
  }

  app-nav {
    grid-area: nav;
    margin-left: 0;
    background-color: #153240;
  }

  app-adapter-details-nav {
    grid-area: adapter-details-nav;
    margin-left: 0;
    background-color: #E9E9E6;
    border-right: solid 1px lightgray;
  }

  app-adapter-integration-nav {
    grid-area: adapter-integration-nav;
    margin-left: 0;
    background-color: #E9E9E6;
    border-right: solid 1px lightgray;
  }

  app-security-nav {
    grid-area: security-nav;
    margin-left: 0;
    background-color: #E9E9E6;
    border-right: solid 1px lightgray;
  }

  app-config-nav {
    grid-area: config-nav;
    margin-left: 0;
    background-color: #E9E9E6;
    border-right: solid 1px lightgray;
  }

  app-privacy-policy,
  app-adapters,
  app-adapter-details,
  app-adapter-integration,
  app-adapter-integrations,
  app-integrations,
  app-integration-details,
  app-security,
  app-config,
  app-add-edit-user,
  app-add-edit-admin-ui-user,
  app-adapter-maintenance,
  app-auth{
    grid-area: content;
    background-color: white;
  }

  app-adapter-details-health,
  app-adapter-details-config,
  app-adapter-details-integrations,
  app-adapter-audit-log,
  app-adapter-details-logs
   {
    grid-area: adapter-details-content;
    background-color: white;
  }

  app-adapter-integration-health,
  app-adapter-integration-details,
  app-adapter-integration-users,
  app-adapter-integration-users-jobs,
  app-adapter-integration-jobs,
  app-adapter-integration-scheduled-jobs,
  app-adapter-integration-schemas,
  app-adapter-integration-stats,
  app-adapter-integration-mappings,
  app-adapter-integration-querytool,
  app-adapter-integration-batchjobs,
  app-adapter-integration-audit-logs,
  app-adapter-integration-logs,
  app-adapter-integration-queue-listener,
   {
    grid-area: adapter-integration-content;
    background-color: white;
  }

  app-security-users,
  app-security-admin-ui-users,
  app-security-audit-logs,
  app-security-add-edit-user,
  app-security-add-edit-admin-ui-user
  {
    grid-area: security-content;
    background-color: white;
  }

  app-config-integration-types,
  app-config-environments
  {
    grid-area: config-content;
    background-color: white;
  }

  app-footer {
    grid-area: footer;
    background-color:#ffffff;
    color: black;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid grey;
  }

  ul li {
    color: white;
  }
  ul li a {
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 15px;
  }

.mat-button {
  color: #4D6A78;
}

.mat-flat-button {
  background-color: #4D6A78;
  color: white;
}

.mat-stroked-button {
  border: 1px solid #4D6A78 !important;
}



  @media (max-width: 991px) {
    app-nav
    .container {
      grid-template-columns: 1fr;
      grid-template-areas:
        "header"
        "nav"
        "content"
        "footer";
      grid-template-rows:auto minmax(60px, auto) 1fr minmax(70px, auto) auto;
    }
  }

  @media (max-width: 991px) {
    app-adapter-details-nav
    .adapter-details-container {
      grid-template-columns: 1fr;
      grid-template-areas:
        "adapter-details-header"
        "adapter-details-nav"
        "adapter-details-content";
      grid-template-rows:auto minmax(60px, auto) 1fr minmax(70px, auto) auto;
    }
  }

  @media (max-width: 991px) {
    app-adapter-integration-nav
    .adapter-integration-container {
      grid-template-columns: 1fr;
      grid-template-areas:
        "adapter-integration-header"
        "adapter-integration-nav"
        "adapter-integration-content";
      grid-template-rows:auto minmax(60px, auto) 1fr minmax(70px, auto) auto;
    }
  }



[hidden] { display: none !important;}
